import jwtDecode from 'jwt-decode';

export const  useValidateJwtExpiry = (jwtToken) => {
        if (!jwtToken) {
            return false;
        }
        const decodedToken = jwtDecode(jwtToken);
        const currentTime = Date.now() / 1000;

        return decodedToken.exp >= currentTime;
};
