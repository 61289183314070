import '../src/css/global.css';
import '../src/css/bulma.css';
import React from "react";
import ReactDOM from 'react-dom/client';

import {PostsProvider} from "./context/posts-context";
import {AuthProvider} from "./context/auth-context";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import PostsPage from "./pages/PostsPage";
import SubmitPage from "./pages/SubmitPage";
import LoginPage from "./pages/LoginPage";
import NotFoundPage from "./pages/notFoundPage";
import Root from "./pages/Root";
import Modal from "./components/PageComponents/WinesPage/Modal";
import Navbar from "./components/navigation/navbar";
import PrivateRoute from "./components/navigation/private-route";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <NotFoundPage />
    },
    {
        path: "wines",
        element: <PostsPage/>,
    },
    {
        path: "wines/:wineId",
        element: <Modal />,
    },
    {
        path: "login",
        element: <LoginPage/>,
    },
    {
        path: "/private",
        element: <PrivateRoute>
                    <SubmitPage/>
                 </PrivateRoute>,
    },
]);

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);
root.render(
    <AuthProvider>
    <PostsProvider>
        <Navbar />
        <RouterProvider router={router} />
    </PostsProvider>
    </AuthProvider>
);
