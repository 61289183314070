import React, {useContext, useReducer} from 'react';
import '../../../css/login.css'
import { AuthContext } from '../../../context/auth-context';
import {useNavigate} from "react-router";

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_MODEL':
            return { ...state, [action.payload.name]: action.payload.value };
        case 'CREATE_ERROR':
            return {...state,  ...action.payload };
        case 'SET_ANIMATION':
            return {...state,  ...action.payload };
        default:
            return state;
    }
};
const LoginForm = () => {
    const initialState = {
        animationClass: 'login-form-container',
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isAuthenticated, login } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const {name, value} = e.target;
        dispatch({ type: 'SET_MODEL', payload: { name, value } });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const credentials = {email: state.username, password: state.password}
        await login(credentials)
            .then((r) => {
                if (r.status === '200') {
                    dispatch({
                        type: 'SET_ANIMATION',
                        payload: {animationClass: 'login-form-container slide-out-fwd-center'}
                    });
                } else {
                    dispatch({
                        type: 'CREATE_ERROR',
                        payload: {error: 'Invalid Username or Password'}
                    });
                }
            })
    };

    if (isAuthenticated) {
        navigate("/private");
        return null;
    }

    return (
        <form className={state.animationClass} onSubmit={handleSubmit}>
            {state.error && <p className="error-message" >{state.error}</p>}
            <div>
                <label className="login-form-input-label">Username:</label>
                <input
                    name="username"
                    className="login-form-input"
                    type="email"
                    value={state.username}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label className="login-form-input-label">Password:</label>
                <input
                    name="password"
                    className="login-form-input"
                    type="password"
                    value={state.password}
                    onChange={handleChange}
                />
            </div>
            <button className="login-form-submit" type="submit">
                Login
            </button>
        </form>
    );
};
export default LoginForm;
