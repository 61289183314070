import ImageWithPlaceholder from "./ImageWithPlaceholder";
import React from "react";
import Favourite from "./favourite";

const Card = ({id, post, grape, year, description, imageName, onClick}) => {
//test, simply commenting for the sake of commenting pushing without deleting
    return (
        <div className="card" onClick={onClick} style={{cursor: "pointer"}}>
            <div className="card-image">
                <figure className="image is-1by1">
                    <ImageWithPlaceholder url={`https://ivsapi.azurewebsites.net/images/${imageName}`} name={post} />
                    {/*<ImageWithPlaceholder url={`http://localhost:5051/images/${imageName}`} name={post} />*/}
                </figure>
            </div>
            <div className="card-content">
                <div className="media-content">
                    <div className="inline">
                        <p className="title is-4">{post}</p>
                        <Favourite id={id} />
                        <div className="inline-inputs">
                        <p className="subtitle is-6 field">{grape}</p>
                        <p className="subtitle is-6 field">{year}</p>
                        </div>
                    </div>
                </div>
                <div className="content">{description}</div>
            </div>
        </div>
    );
};
export default Card;
