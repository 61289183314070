import React from "react";
import '../css/root.css';
import img1 from '../media/Images/winemakers-300px.jpg';
import img2 from '../media/Images/winetable-300px.jpg';
import img3 from '../media/Images/winetalker-300px.jpg';
import Hero from "../components/Hero";

const Root = () => {
    return (
        <>
            <Hero />
            <div className="container">
                <section className="section">
                    <div className="grid">
                        <div className="card" style={{width: '90%'}}>
                            <div className="card-image">
                                <figure className="image is-1by1">
                                    <img src={img1} alt="Winemakers" />
                                </figure>
                            </div>
                            <div className="card-content">
                                <div className="media-content">
                                    <p className="title is-4">A World of Wine</p>
                                </div>
                                <div className="content">Discover a world full of different regions and styles. From the rolling hills of Napa Valley to the
                                    picturesque vineyards of Tuscany, there are many fascinating places where wine is produced. In addition to
                                    learning about the regions, you can also expect to learn about the different styles of wine, such as red,
                                    white, sparkling, and more.</div>
                            </div>
                        </div>
                        <div className="card" style={{width: '90%'}}>
                            <div className="card-image">
                                <figure className="image is-1by1">
                                    <img src={img2} alt="Winetable" />
                                </figure>
                            </div>
                            <div className="card-content">
                                <div className="media-content">
                                    <p className="title is-4">Learn to Impress</p>
                                </div>
                                <div className="content">Learn secret tips and recommendations for pairing wine with food. From cheese and charcuterie boards to main
                                    courses and desserts, there are countless delicious pairings to be discovered. Expect to find suggestions for
                                    pairing specific wines with different types of dishes, as well as general tips for selecting the right wine
                                    for a particular meal.</div>
                            </div>
                        </div>
                        <div className="card" style={{width: '90%'}}>
                            <div className="card-image">
                                <figure className="image is-1by1">
                                    <img src={img3} alt="Winetalker" />
                                </figure>
                            </div>
                            <div className="card-content">
                                <div className="media-content">
                                    <p className="title is-4">Connect with History</p>
                                </div>
                                <div className="content">Wine has a rich history and cultural significance, and you can expect to learn about these aspects when
                                    reading a wine blog. From the ancient civilizations that first cultivated grapes for wine to the modern-day
                                    techniques and trends in the industry, there is a wealth of information to be discovered. Expect to learn
                                    about the cultural traditions surrounding wine, such as the rituals of wine-making and the customs of
                                    wine-drinking.</div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="columns" id='about'>
                <div className="column">
                    <h2>About Us</h2>
                    <p>
                        "Hi there! My name is William, and I am a wine blogger with a WSET2 certification. I have always been
                        fascinated by the world of wine and have a particular love for Viognier and Gewürztraminer. Before starting
                        my blog, I lived in Georgia where I had the opportunity to explore the diverse wine regions of the country.
                        My goal with this blog is to share my passion for wine and help others discover new and exciting wines to
                        try."
                    </p>
                </div>
                <div className="column">
                    {/* <img src="../ivs/ivs/public/media/Images/wineharvesters-512px.jpg" alt="Wineharvesters" /> */}
                </div>
            </div>
        </>
    );
};

export default Root;
