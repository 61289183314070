const useExcludedFields = () => {
    return [
        "id",
        "image",
        "name",
        "grape",
        "year",
        "description",
        "imageFile",
        "imageSrc",
        "imageName"
    ];
};
export default useExcludedFields;
