import React, { useCallback, useEffect, useState } from "react";
import useEnumDisplayName from "../../../hooks/use-enum-display-name";
import useExcludedFields from "../../../hooks/use-excluded-fields";
import Loader from "../../Loader";
import "../../../css/tastingNote.css";
/* eslint-disable */
const TastingNote = ({ tastingNote }) => {
    const [model, setModel] = useState({});

    useEffect(() => {
        setModel(tastingNote);
    }, [tastingNote]);

    const renderRows = useCallback(() => {
        if (!model) {
            return <Loader />;
        }
        /* eslint-disable */
        const filteredTastingNote = Object.entries(model).filter(([key]) => {
            return !useExcludedFields().includes(key);
        });
        /* eslint-disable */

        return filteredTastingNote.map(([key, value], index) => {
            const displayField = useEnumDisplayName(key);
            const displayValue = useEnumDisplayName(value);

            return (
                <div
                    className={`tasting-note__row ${index % 2 === 0 ? "even" : "odd"}`}
                    key={key}
                >
                    <span className="tasting-note__label">{displayField}:</span>
                    <span className="tasting-note__value">{String(displayValue)}</span>
                </div>
            );
        });
    }, [model]);

    return (
        <div className="tasting-note">
            <h2 className="tasting-note__heading">Tasting Note</h2>
            {renderRows()}
        </div>
    );
};
/* eslint-disable */
export default TastingNote;
