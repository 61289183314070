import React from 'react';

const Loader = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            width="60"
            height="60"
            className="loader"
        >
            <circle cx="50" cy="50" r="10" fill="none" stroke="#000" strokeWidth="2">
                <animate
                    attributeName="cy"
                    values="50;30;50"
                    dur="1s"
                    repeatCount="indefinite"
                />
            </circle>
            <circle cx="50" cy="50" r="10" fill="none" stroke="#000" strokeWidth="2">
                <animate
                    attributeName="cy"
                    values="50;70;50"
                    dur="1s"
                    repeatCount="indefinite"
                />
            </circle>
            <circle cx="50" cy="50" r="10" fill="none" stroke="#000" strokeWidth="2">
                <animate
                    attributeName="cy"
                    values="50;30;50"
                    dur="1s"
                    repeatCount="indefinite"
                    begin="0.2s"
                />
            </circle>
        </svg>
    );
};

export default Loader;
