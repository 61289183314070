import axios from 'axios';

const API_BASE_URL = 'https://ivsapi.azurewebsites.net/api/wines';
//const API_BASE_URL = 'http://localhost:5051/api/wines';

export const fetchPosts = async () => {
    const response = await axios.get(`${API_BASE_URL}/all`);
    return response.data;
};

export const fetchPost = async (postId) => {
    const response = await axios.get(`${API_BASE_URL}/Id=${postId}`);
    return response.data;
};

export const submitPost = async (model) => {
    const token = localStorage.getItem('jwtToken');

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    };
    const response = await axios.post(`${API_BASE_URL}/wine`, model, config);
    return response.data;
};
