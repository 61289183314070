import {createContext, useEffect, useState} from 'react';
import {fetchPosts} from '../services/post-service';

const PostsContext = createContext(null);

function PostsProvider( { children } ) {
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);

        fetchPosts()
            .then((r) => {
                setPosts(r);
                setIsLoading(false);
            })
            .catch((error) => console.error(error));

        setIsLoading(false);
    }, []);

    const valueToShare = {
        posts,
        isLoading,
    };


    return <PostsContext.Provider value={valueToShare}>
        {children}
    </PostsContext.Provider>
}

export {PostsProvider}
export default PostsContext;
