import React, { useState, useEffect } from 'react';
import {AuthService} from "../services/auth-service";
import {useValidateJwtExpiry} from "../hooks/use-validate-jwt-expiry";

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    /* eslint-disable */
    useEffect(() => {
         const jwtToken = localStorage.getItem('jwtToken');
         const storedUser = localStorage.getItem('user');
         if (useValidateJwtExpiry(jwtToken)) {
             if (storedUser) {
                 setUser(JSON.parse(storedUser));
                 setIsAuthenticated(true);
             }
         }
         else {
             localStorage.removeItem('jwtToken');
             localStorage.removeItem('user');
             setIsAuthenticated(false);
         }
    }, []);
    /* eslint-disable */

    const login = (model) => {
        AuthService(model).then((result) => {
            if (result) {
                const token = result.data;
                const userData = { user: model.email, role: 'user' };
                localStorage.setItem('jwtToken', token);
                localStorage.setItem('user', JSON.stringify(userData));
                setUser(userData);
                setIsAuthenticated(true);
            }
        });
    };

    const logout = () => {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('user');
        setUser(null);
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, isAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
