import { useContext, useState } from "react";
import { fetchPost } from "../services/post-service";
import SinglePost from "../components/PageComponents/WinesPage/SinglePost";
import List from "../components/PageComponents/WinesPage/List";
import Modal from "../components/PageComponents/WinesPage/Modal";
import PostsContext from "../context/posts-context";
import Loader from "../components/Loader";


const PostsPage = () => {

    const { posts } = useContext(PostsContext);
    const [modalData, setModalData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    if (!posts) {
        return <Loader/>;
    }
    const handleClick = (e, id) => {
        console.log(e.target.classList)
        e.preventDefault();

        if (e.target.classList.contains('favourite')) {
            return;
        }

        fetchPost(id).then(() => {
            setIsModalOpen(true);
            fetchPost(id)
                .then((r) => {
                    return setModalData(r);
                })
        });
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div style={{margin: '5% 0'}} >
            <List posts={posts} handleClick={handleClick} />
            {isModalOpen && (
                <Modal onClose={handleCloseModal} modalData={modalData}>
                    <SinglePost modalData={modalData}></SinglePost>
                </Modal>
            )}
        </div>
    );
};

export default PostsPage;
