import React, {useEffect, useState} from 'react';
import '../../css/navbar.css';
import logo from '../../media/Images/logo.png';
const Navbar = () => {
    const [isMobile, setIsMobile] = useState(600 > window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(600 > window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    return (
        <header className="nav-bar">
            {!isMobile && <a href="/" className="menu-header_item">
                <img src={logo} alt="IVS Logo" width="48px"/>
            </a>}
            <nav className="menu-header">
                <ul>
                    <li><a href="/wines" className="menu-header_item">Reviews</a></li>
                    <li><a href="/services" className="menu-header_item">Services</a></li>
                    <li><a href='/' className="menu-header_item">About</a></li>
                </ul>
            </nav>
        </header>

    );
};

export default Navbar;
