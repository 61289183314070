import React, {useContext, useMemo, useReducer, useState} from "react";
import "../../../css/form.css"
import useFormFields, {FieldType} from "../../../hooks/use-form-fields";
import ImageField from "./ImageField";
import useValidateForm from "../../../hooks/use-validate-form";
import useModel from "../../../hooks/use-model";
import {submitPost} from "../../../services/post-service";
import {AuthContext} from '../../../context/auth-context';

/* eslint-disable */
const formReducer = (state, action) => {
    /* eslint-disable */
    const initialModel = useModel();
    /* eslint-disable */
    switch (action.type) {
        case "UPDATE_FIELD":
            return { ...state, [action.field]: action.value };
        case "UPDATE_IMAGE":
            return { ...state, imageFile: action.value.imageFile, imageSrc: action.value.imageSrc}
        case "RESET_FORM":
            return initialModel;
        default:
            return state;
    }
};
/* eslint-disable */

const SubmitForm = () => {
    const initialModel = useMemo (() => useModel(), [])
    const [model, dispatch] = useReducer(formReducer, initialModel);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { logout } = useContext(AuthContext);
    const {validateForm} = useValidateForm();
    const fields = useFormFields();

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        if (!validateForm(model)) {
            window.alert('Please ensure all fields are correctly filled out');
            setIsSubmitting(false);
            return;
        }

        submitPost(model)
            .then(() => {
                dispatch({ type: "RESET_FORM" });
                window.alert("Form Submit Successful");
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                    console.error('Unauthorized');
                    return null;
                }
                console.error('Error submitting form:', error);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };


    /* eslint-disable */
    const handleChange = (event, id) => {
        switch (id) {
            case 'imageFile':
                const imageFile = event.target.files[0];
                const reader = new FileReader();
                reader.onload = () => {
                    const imageSrc = reader.result;
                    dispatch({ type: "UPDATE_IMAGE", field: id, value: {imageFile, imageSrc} });
                }
                reader.readAsDataURL(imageFile);
                break;
            default:
                const { name, value } = event.target;
                dispatch({ type: "UPDATE_FIELD", field: name, value });
                break;
        }
    };
    /* eslint-disable*/

    const getProps = (field) => {
        const { name, type } = field;
        const commonProps = {
            id: name,
            name,
            value: model[name] || "",
            onChange: handleChange,
        };

        switch (type) {
            case "textarea":
                return { ...commonProps };
            case "select":
                return { ...commonProps,
                    children: field.options.map((option) =>
                        <option key={option} value={option}>{option}</option>
                    )
                };
            default:
                return { ...commonProps, type };
        }
    };

    const renderFields = () => {
        return fields.map((field) => {
            const { type, label } = field;
            const fieldProps = getProps(field);

            switch (type) {
                case FieldType.TEXTAREA:
                    return (
                        <div key={field.name} className="form-field">
                            <label htmlFor={field.name}>{label}:</label>
                            <textarea className="form-input" {...fieldProps} />
                        </div>
                    );
                case FieldType.SELECT:
                    return (
                        <div key={field.name} className="form-field">
                            <label htmlFor={field.name}>{label}:</label>
                            <select className="form-select" {...fieldProps}>
                                <option key="empty" value="">
                                    Please select...
                                </option>
                                {field.options.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                    );
                case FieldType.IMAGE:
                    return (
                        <div key={field.name} className="form-field">
                            <ImageField label={label} fieldProps={fieldProps} ></ImageField>
                        </div>
                    );
                case FieldType.TEXT:
                default:
                    return (
                        <div key={field.name} className="form-field">
                            <label htmlFor={field.name}>{label}:</label>
                            <input className="form-input" type={type} {...fieldProps} />
                        </div>
                    );
            }
        });
    };

    return (
        <form className="form-container" onSubmit={handleSubmit}>
            {renderFields()}
            <button className="submit-button" type="submit" disabled={isSubmitting}>
                Submit
            </button>
        </form>
    );
};
export default SubmitForm;
