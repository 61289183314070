import React from 'react';
import LoginForm from '../components/PageComponents/LoginPage/LoginForm';
import '../css/hero.css';

const LoginPage = () => {


    return (
        <>
            <div className="hero">
                <LoginForm />
            </div>
        </>
    );
};
export default LoginPage;
