import useFormFields from "./use-form-fields";

const useModel = () => {
    const names = useFormFields().map(field => field.name);

    return names.reduce((model, name) => {
        return { ...model, [name]: "" };
    }, {});
};
export default useModel;
