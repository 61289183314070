import {useEffect, useState} from "react";
import placeholderImage from '../../../media/Images/placeholder_ivs.jpg';

const ImageWithPlaceholder = ({name, url, className}) => {
    const [isInitialised, setIsInitialised] = useState(false);
    const image = new Image();

    const handleImageLoaded = () => {
        setIsInitialised(true);
    };

    const handleImageError = () => {
        setIsInitialised(false);
    };

    useEffect(() => {
        image.onload = () => handleImageLoaded();
        image.onError = () => handleImageError();
        image.src = url;
    });

    return (
        <>
            {isInitialised ?
                (<img
                    onLoad={() => handleImageLoaded()}
                    onError={() => handleImageError()}
                    className={className}
                    src={url}
                    alt={`Label of ${name} wine bottle`}
                />)
                : (<img
                    src={placeholderImage}
                    alt='Wine bottle placeholder'
                    className={className}
                />)}
        </>
    );
};
export default ImageWithPlaceholder;
