import React from 'react';
import '../css/hero.css';
import {Link} from "react-router-dom";

const Hero = () => {
    return (
        <div className="hero">
            <div className="hero-overlay">
                <div className="hero-content">
                    <h1 className="hero-title">In Vino</h1>
                    <h2 className="hero-subtitle">Simplicity</h2>
                    <div className="hero-buttons">
                        <Link to='/wines'>
                        <button className="hero-button">Explore Reviews</button>
                        </Link>
                        <a href='#about'>
                        <button className="hero-button">Learn More</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
