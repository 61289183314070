
const useValidateForm = () => {
//TODO: sort this out
/* eslint-disable */
    const validateForm = (model) => {
        if (Object.keys(model).length > 1) {
            const entries = Object.entries(model);
            for (const [_, value] of entries) {
                if (!value) {
                    return false;
                }
                return true;
            }
            return false;
        }
    };
    return {validateForm};
    /* eslint-disable */
};
export default useValidateForm;
