import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';
import Loader from "../Loader";

const PrivateRoute = ({ children }) => {
    const { isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        } else {
            setIsLoading(false);
        }
    }, [isAuthenticated, navigate]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            {children}
        </>
    );
};

export default PrivateRoute;
