import React from "react";
import TastingNote from "./TastingNote";
import Loader from "../../Loader";
import '../../../css/singlePage.css';
import Favourite from "./favourite";
import ImageWithPlaceholder from "./ImageWithPlaceholder";

const SinglePost = ({ modalData }) => {
    //const src = `https://ivsapi.azurewebsites.net/images/${modalData?.imageName ?? ""}`;
    const src = `http://localhost:5051/images/${modalData?.imageName ?? ""}`;

    if (!modalData) {
        return <Loader />;
    }
    return (
        <div className="page">
            <div className="leftcol">
                <ImageWithPlaceholder url={src} name={modalData?.name} className="imageModal" />
                <div className="inline">
                    <h1 className="name">{modalData?.name}</h1>
                    <Favourite id={modalData?.id} />
                </div>
                <div className="under-name">
                    <div className="inline">
                        <span className="grape">{modalData?.grape}</span>
                        <span className="year">{modalData?.year}</span>
                    </div>
                </div>
                <div className="under-image-and-text">
                    <p className="description">{modalData?.description}</p>
                </div>
            </div>
            <div className="rightcol">
                <TastingNote tastingNote={modalData} />
            </div>
        </div>
    );
};

export default SinglePost;
