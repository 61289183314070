import Card from "./Card";
import { useContext } from "react";
import PostsContext from "../../../context/posts-context";

const List = ({ handleClick }) => {
    const { posts } = useContext(PostsContext);

    const renderedPosts = posts.map((post) => {
        return (
            <Card
                key={post.id}
                id={post.id}
                post={post.name}
                imageName={post.imageName}
                description={post.description}
                grape={post.grape}
                year={post.year}
                onClick={(e) => handleClick(e, post.id)}
            />
        );
    });

    return (
        <div className="container">
            <section className="section">
                <div className="grid">
                    {renderedPosts}
                </div>
            </section>
        </div>
    );
};
export default List;
