
export const FieldType = {
    TEXT: "text",
    TEXTAREA: "textarea",
    SELECT: "select",
    IMAGE: "image",
};

const useFormFields = () => {
    const clarityOptions = ["Clear", "Cloudy", "Hazy"];
    const colourIntensityOptions = ["Pale", "Medium", "Deep"];
    const conditionOptions = ["Clean", "Unclean"];
    const aromaIntensityOptions = ["Light", "Medium", "Pronounced"];
    const sweetnessOptions = ["Dry", "OffDry", "Medium", "Sweet"];
    const acidityOptions = ["Low", "MediumMinus", "Medium", "MediumPlus", "High"];
    const tanninOptions = ["None", "Low", "MediumMinus", "Medium", "MediumPlus", "High"];
    const alcoholOptions = ["Low", "MediumMinus", "Medium", "MediumPlus", "High"];
    const bodyOptions = ["Light", "MediumMinus", "Medium", "MediumPlus", "Full"];
    const lengthOptions = ["Short", "MediumMinus", "Medium", "MediumPlus", "Long"];


    return [
        { label: "Name", name: "name", type: FieldType.TEXT },
        { label: "Description", name: "description", type: FieldType.TEXTAREA, rows: 4, cols: 50 },
        { label: "Year", name: "year", type: FieldType.TEXT },
        { label: "Grape", name: "grape", type: FieldType.TEXT },

        { label: "Clarity", name: "clarity", type: FieldType.SELECT, options: clarityOptions },
        { label: "Colour Intensity", name: "colourIntensity", type: FieldType.SELECT, options: colourIntensityOptions },
        { label: "Colour", name: "colour", type: FieldType.TEXT },

        { label: "Condition", name: "condition", type: FieldType.SELECT, options: conditionOptions },
        { label: "Aroma Intensity", name: "aromaIntensity", type: FieldType.SELECT, options: aromaIntensityOptions },
        { label: "Aroma Characteristics", name: "aromaCharacteristics", type: FieldType.TEXTAREA, rows: 4, cols: 50 },

        { label: "Sweetness", name: "sweetness", type: FieldType.SELECT, options: sweetnessOptions },
        { label: "Acidity", name: "acidity", type: FieldType.SELECT, options: acidityOptions },
        { label: "Tannin", name: "tannin", type: FieldType.SELECT, options: tanninOptions },
        { label: "Alcohol", name: "alcohol", type: FieldType.SELECT, options: alcoholOptions },
        { label: "Body", name: "body", type: FieldType.SELECT, options: bodyOptions },
        { label: "Flavor Characteristics", name: "flavourCharacteristics", type: FieldType.TEXTAREA, rows: 4, cols: 50 },
        { label: "Length", name: "length", type: FieldType.SELECT, options: lengthOptions },

        { label: "Image", name: "imageFile", type: FieldType.IMAGE }
    ];
};
export default useFormFields;
