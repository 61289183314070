import React, { useRef, useState } from "react";
import  resizer from "react-image-file-resizer";

const ImageField = ({ fieldProps }) => {
    const { onChange: onChangeProp, id, name } = fieldProps;
    const fileInputRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleFileButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileInputChange = async (event)  => {
        const files = event.target.files;
        if (files.length > 0) {
            const uploadedFile = files[0];

            try {
                const resizedImage = await handleFileResize(uploadedFile);
                console.log(resizedImage.size);
                setSelectedImage(resizedImage);
                onChangeProp(event, id);
            } catch (error) {
                console.error("Error processing image:", error);
            }
        }
    };

    const handleFileResize = (file) => {
        return new Promise((resolve, reject) => {
            try {
                resizer.imageFileResizer(
                    file,
                    512,
                    512,
                    "JPEG",
                    85,
                    0,
                    (uri) => {
                        const filename = file.name;
                        const type = "image/jpeg";
                        const resizedFile = new File([uri], filename, {type});
                        resolve(resizedFile);
                    },
                    "base64",
                    512,
                    512,
                );
            }
            catch {
                console.error(reject);
            }
        });
    };

    return (
        <div className="form-field">
            <label htmlFor={id}>{name.charAt(0).toUpperCase() + name.slice(1)}:</label>
            <input
                ref={fileInputRef}
                type="file"
                accept="image/jpeg"
                id={id}
                style={{ display: "none" }}
                onChange={handleFileInputChange}
            />
            <button
                type="button"
                onClick={handleFileButtonClick}
                className={`image-upload ${selectedImage ? "has-image" : ""}`}
                style={{ backgroundImage: selectedImage && `url(${URL.createObjectURL(selectedImage)})` }}
            >
                {selectedImage ? "Change Image" : "Select File"}
            </button>
        </div>
    );
};

export default ImageField;
