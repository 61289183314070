import { createPortal } from 'react-dom';
import { useEffect } from 'react';
import '../../../css/modal.css';

function Modal({ onClose, children, actionBar }) {
    useEffect(() => {
        document.body.classList.add('overflow-hidden');

        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, []);

    return createPortal(
        <div>
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="modal-container">
                <div className="modal-content">
                    {children}
                    <div className="modal-action-bar">{actionBar}</div>
                </div>
            </div>
        </div>,
        document.querySelector('.modal-root')
    );
}

export default Modal;
