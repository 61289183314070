import SubmitForm from "../components/PageComponents/SubmitPage/SubmitForm";
import '../css/hero.css';

const SubmitPage = () => {
    return (
        <div style={{marginTop: '48px'}}>
        <SubmitForm />
        </div>
    )
}
export default SubmitPage;
